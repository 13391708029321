/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block', // This is important to make the link occupy the full space of the Card
});

const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};

const formatPrice = (priceRange) => {
    if (priceRange.startsWith("Subscription|")) {
        const parts = priceRange.split('|');
        return `$${parts[1]} ${parts[2]} cloud bundle`;
    } else if (Number(priceRange) === 0) {
        return 'Free';
    }
    return `$${priceRange}`;
};

const Product = ({ item }) => (
    <StyledLink to={`/productreviewpost/${item.slug}`}>
        <Card
            sx={{
                maxWidth: '100%',
                height: '360px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                backgroundColor: '#fff',
                borderRadius: '15px',
                // border: '2px solid #262931'
            }}
        >
            <StyledCardMedia
                image={item.fileUrl ? item.fileUrl : "https://via.placeholder.com/640x360"}
                title="ProductReviews item image"
            />
            <CardContent>
                <Typography
                    variant="h5"
                    color="text.secondary"
                    sx={{
                        color:'#333333'
                    }}
                >
                    {truncateText(item.title, 25)} {/* Adjust the character limit as needed */}
                </Typography>
                <Typography
                    variant="body2"
                    color="#4b5360"
                    sx={{
                        textAlign: 'left' // Left align the short description
                    }}
                >
                    {truncateText(item.shortDescription, 90)} {/* Adjust the character limit as needed */}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: item.priceRange.startsWith("Subscription|") ? 'teal' : 'teal',
                    }}
                >
                    {formatPrice(item.priceRange)}
                </Typography>
            </CardContent>
        </Card>
    </StyledLink>
);

export default Product;

