/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Box, Card, CardContent, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import ProductReviewList from "./ProductReviewList";
import ReviewsIcon from '@mui/icons-material/Reviews';
import {Helmet} from "react-helmet";
import LatestBlogPosts from '../Latest/LatestBlogPosts';
import LatestDeals from '../Deals/LatestDeals';
import { keyframes } from '@mui/system';

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledTypographyBio = styled(Typography)({
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    marginBottom: '1rem',
});

const StyledCard = styled(Card)({
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    padding: '2rem',
    marginTop: '0rem',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem',
    // border: '2px solid #262931'
});

const ProductReviews = () => {
    const { user, isAuthenticated } = useAuth0();
    const [petOwnerProfile, setPetOwnerProfile] = useState({
        name: '',
        bio: '',
        fileUrl: '',
    });

    useEffect(() => {
        const fetchPetOwnerProfile = async () => {
            if (isAuthenticated && user) {
                try {
                    const response = await fetch('/.netlify/functions/getPetOwnerProfile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userEmail: user.email }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error ${response.status}`);
                    }

                    const petOwnerProfileData = await response.json();
                    setPetOwnerProfile(petOwnerProfileData);
                } catch (error) {
                    console.error('Error fetching pet owner profile:', error);
                }
            }
        };

        fetchPetOwnerProfile();
    }, [user, isAuthenticated]);

    return (
        <Container maxWidth="false">
            <Helmet>
                <title>DirtStack - Home</title>
                <meta property="og:title" content="DirtStack - Product Reviews" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://dirtstack.com" />
                <meta property="og:description" content="DirtStack - the trusted product review app" />
                <link rel="alternate" hrefLang="en" href={`https://dirtstack.com`} />
                <link rel="alternate" hrefLang="x-default" href={`https://dirtstack.com`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="DirtStack - the App for product reviews" />
                <meta name="twitter:description" content="DirtStack - the App for product reviews" />
                <meta name="twitter:image" content="%PUBLIC_URL%/apple-touch-icon.png" />
                <meta name="twitter:site" content="@dirtstack" />
            </Helmet>

            <Grid container spacing={3}>
                {/* Left column for LatestBlogPosts */}
                <Grid item md={2}>
                    <LatestDeals />
                </Grid>

                {/* Center/Main content */}
                <Grid item xs={12} md={8}>

                    <Typography
                        variant="h6"
                        sx={{
                            marginBottom: 4,
                            marginTop: 0,
                            textAlign: 'center',
                            fontFamily: "'Poppins', sans-serif",
                            fontWeight: 'bold',
                            letterSpacing: '0.05em',
                            textTransform: 'uppercase',
                            background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: `${rainbowAnimation} 10s ease infinite`,
                            backgroundSize: '200% 200%',
                            '@media (max-width:600px)': {
                                fontSize: '1.2em',
                            },
                        }}
                    >
                        Dirtstack Product Reviews
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0 }}>
                        <ProductReviewList />
                    </Box>
                </Grid>

                {/* Right column */}
                <Grid item md={2}>
                    {/* Content for the right column, if any, goes here */}
                    <LatestBlogPosts />
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProductReviews;



