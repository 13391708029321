/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, IconButton } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteIcon from '@mui/icons-material/Favorite';
import likeComment from '../../pages/likeComment';
import {useAuth0} from "../../pages/imports";
import fetchLikedComments from "../../pages/fetchLikedComments";
import countCommentLikes from "../../pages/countCommentLikes";

const Comment = ({
                     id,
                     ownerName,
                     text,
                     timestamp,
                     isLiked: initialIsLiked,
                     likeCount: initialLikeCount,
                     userEmail,
                     userPicture,
                     postId,
                 }) => {
    const { user, isAuthenticated } = useAuth0();
    const [likedComments, setLikedComments] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);
    const initialIsLikedBool = initialIsLiked !== undefined ? initialIsLiked : false;
    const initialLikeCountNumber = initialLikeCount !== undefined ? initialLikeCount : 0;
    const [isLiked, setIsLiked] = useState(initialIsLikedBool);
    const [likeCount, setLikeCount] = useState(initialLikeCountNumber);
    const [commentLikesCount, setCommentLikesCount] = useState(0);

    const fetchCommentLikesCount = async () => {
        try {
            const response = await countCommentLikes(id);
            setCommentLikesCount(response);
        } catch (error) {
            console.error('Failed to fetch likes count:', error);
        }
    };

    useEffect(() => {
        // console.log('isAuthenticated', isAuthenticated);
        // console.log('user', user);
        const fetchUserData = async () => {
            setLoading(true);
            try {
                if (isAuthenticated && user) {
                    fetchLikedComments(user.email, setLikedComments);
                }
                fetchCommentLikesCount();
            } catch (error) {
                console.error('Error fetching posts:', error);
                setError(error.message);
                setLoading(false);
                setHasFetched(true);
            }
        };
        fetchUserData();
    }, [isAuthenticated, user]);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar src={userPicture} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <React.Fragment>
                        <Typography component="span" variant="body2" color="textPrimary">
                            {ownerName}
                        </Typography>
                        {` - ${text}`}
                    </React.Fragment>
                }
                secondary={new Date(timestamp).toLocaleString()}
            />
            <IconButton onClick={() => {
                // console.log('user.email', user.email);
                // console.log('postId', postId);
                // console.log('id', id);
                // console.log('likedComments[id]', likedComments[id]);
                likeComment(user.email, postId, id, likedComments[id], setLikedComments, setCommentLikesCount)
                    .then(() => {
                        // console.log('inside .then');
                        setIsLiked(!isLiked);
                        setLikeCount(isLiked ? likeCount - 1 : likeCount + 1);
                    }, error => {
                        // console.log('inside .then error callback');
                        // console.error('Failed to like the comment:', error);
                    })
                    .catch(error => {
                        // console.log('inside .catch');
                        console.error('Failed to like the comment:', error);
                    });
            }}>
                <FavoriteIcon color={isLiked ? 'primary' : 'inherit'} />
                {likeCount}
            </IconButton>
            {/*<IconButton>*/}
            {/*    <ReplyIcon />*/}
            {/*</IconButton>*/}
        </ListItem>
    );
};

export default Comment;
