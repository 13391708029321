import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const StyledList = styled('ul')({
    listStyleType: 'none',
    paddingLeft: 0,
    borderTop: '1px solid #e0e0e0',  // Added border-top here
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
});

const StyledListItem = styled('li')({
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
    paddingBottom: '10px',
    paddingTop: '10px',
});

const LatestBlogPosts = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch("/.netlify/functions/getBlogPostItemsLatest")
            .then(response => response.json())
            .then(data => {
                setBlogPosts(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching latest blog posts:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="latest-blog-posts"
             style={{
                 padding: '10px 20px'
        }}
        >
            <Typography
                variant="h5"
                component="h2"
                align="left"
                sx={{
                    color:'#333333'
                }}
            >  {/* Added align property here */}
                Latest
            </Typography>
            <StyledList>
                {blogPosts.map(post => (
                    <StyledListItem key={post.blogPost.id}>
                        <StyledLink to={`/blogpost/${post.slugs[0]?.slugName}`}>
                            <Typography
                                variant="body1"
                                align="left"
                                sx={{
                                    color:'#333333',
                                    padding:'10px 0',
                                    marginRight: '10px'
                                }}
                            >  {/* Added align property here */}
                                {post.blogPost.title}
                            </Typography>
                            <Typography
                                variant="body2"
                                style={{
                                    fontSize: '0.8rem',
                                    color: 'gray',
                                    padding: '5px 0'
                            }}
                                align="left"
                            >  {/* Added align property here */}
                                Updated on: {post.blogPost.updateDate}
                            </Typography>
                        </StyledLink>
                    </StyledListItem>
                ))}
            </StyledList>
        </div>
    );
};

export default LatestBlogPosts;
