import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Product from "./Product";

const ProductReviewList = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await fetch('/.netlify/functions/getProductReviewItems', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }

                const itemData = await response.json();

                // Flatten the data structure
                const flattenedItems = itemData.map(data => {
                    const { productReview, categories, brands, authors, tags } = data;
                    return {
                        ...productReview,
                        categories,
                        brands,
                        authors,
                        tags
                    };
                });

                setItems(flattenedItems);

            } catch (error) {
                console.error('Error fetching product reviews:', error);
            }
        };

        fetchItems();
    }, []);


    const getItemsForCategory = (categoryName) => {
        return items.filter(item =>
            item.categories.some(category => category.categoryName === categoryName)
        );
    }

    const categories = ["Simulation", "Games", "Home", "Cars", "Electronics", "Computers", "Game Consoles", "VR", "Software", "Single Board Computers", "Personal Care"];

    return (
        <div>
            {categories.map((categoryName) => {
                const categoryItems = getItemsForCategory(categoryName);
                return (
                    categoryItems.length > 0 && (
                        <div key={categoryName}>
                            <Typography
                                variant="h4"
                                gutterBottom
                                color="text.secondary"
                                sx={{
                                    color: '#4b5360',
                                    marginBottom: '16px'  // Adjust this value to reduce the space below the category name
                                }}
                            >
                                {categoryName}
                            </Typography>
                            <Grid container spacing={3}>  {/* Reduced spacing */}
                                {categoryItems.map((item) => (
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        key={item.id}
                                        style={{ minHeight: '400px' }}  // Adjusted min height
                                    >
                                        <Product item={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )
                );
            })}
        </div>
    );

};

export default ProductReviewList;
