// SiteUrls.js
export const siteUrls = [
    { title: "Welcome to the DirtStack Business Blog", url: "https://dirtstack.com/blogpost/introducing-dirtstack-trusted-product-guide" },
    { title: "Windows 10", url: "https://dirtstack.com/productreviewpost/windows-10" },
    { title: "Windows 11", url: "https://dirtstack.com/productreviewpost/windows-11" },
    { title: "Apple Mac Mini (M1, 8GB RAM)", url: "https://dirtstack.com/productreviewpost/apple-mac-mini" },
    { title: "Apple iPad Pro", url: "https://dirtstack.com/productreviewpost/apple-ipad-pro" },
    { title: "macOS Monterey", url: "https://dirtstack.com/productreviewpost/macos-monterrey" },
    { title: "Raspberry Pi 4", url: "https://dirtstack.com/productreviewpost/raspberry-pi-4" },
    { title: "NVIDIA Jetson Nano", url: "https://dirtstack.com/productreviewpost/nvidia-jetson-nano" },
    { title: "Tesla Model X", url: "https://dirtstack.com/productreviewpost/tesla-model-x" },
    { title: "Tesla Model Y", url: "https://dirtstack.com/productreviewpost/tesla-model-y" },
    { title: "Tesla Model S", url: "https://dirtstack.com/productreviewpost/tesla-model-s" },
    { title: "Tesla Model 3", url: "https://dirtstack.com/productreviewpost/tesla-model-3" },
    { title: "Rivian R1T Electric Pick-Up Truck", url: "https://dirtstack.com/productreviewpost/rivian-r1t-electric-pick-up-truck" },
    { title: "Dyson Air Multiplier Tower Fan", url: "https://dirtstack.com/productreviewpost/dyson-air-multiplier-tower-fan" },
    { title: "Dyson Ball Multifloor Upright Vacuum", url: "https://dirtstack.com/productreviewpost/dyson-ball-multifloor-upright-vacuum" },
    { title: "Amazon Fire Stick", url: "https://dirtstack.com/productreviewpost/amazon-fire-stick" },
    { title: "Apple TV", url: "https://dirtstack.com/productreviewpost/apple-tv" },
    { title: "Roku", url: "https://dirtstack.com/productreviewpost/roku" },
    { title: "PlayStation 5", url: "https://dirtstack.com/productreviewpost/playstation-5" },
    { title: "Xbox Series X", url: "https://dirtstack.com/productreviewpost/xbox-series-x" },
    { title: "Nintendo Switch", url: "https://dirtstack.com/productreviewpost/nintendo-switch" },
    { title: "Oculus Quest 2", url: "https://dirtstack.com/productreviewpost/oculus-quest-2" },
    { title: "Logitech MX Master 3S", url: "https://dirtstack.com/productreviewpost/logitec-mx-master-3s" },
    { title: "SanDisk 2TB Extreme PRO", url: "https://dirtstack.com/productreviewpost/sandisk-2tb-extreme-pro" },
    { title: "Blue Yeti X", url: "https://dirtstack.com/productreviewpost/blue-yeti-x" },
    { title: "Dyson Airwrap", url: "https://dirtstack.com/productreviewpost/dyson-airwrap" },
    { title: "Beam Analysis with NebulaSolver's App", url: "https://dirtstack.com/productreviewpost/beam-analysis-with-nebulasolver" },
    { title: "Dogoku", url: "https://dirtstack.com/productreviewpost/dogoku" },
    { title: "Shepherds of Prey", url: "https://dirtstack.com/productreviewpost/shepherds-of-prey" },
    { title: "Catdoku", url: "https://dirtstack.com/productreviewpost/catdoku-cat-themed-sudoku-online-multiplayer-game" },
    { title: "Equation Solver with NebulaSolver - DirtStack Review", url: "https://dirtstack.com/productreviewpost/solving-non-linear-equations-with-nebulasolver" },
    { title: "Non-Linear Equation Solver with Trigonometric, Exponential, and Logarithmic Capabilities - DirtStack Review", url: "https://dirtstack.com/productreviewpost/non-linear-equation-solver-with-trigonometric-exponential-logarithms" },
    { title: "Battery Performance with NebulaSolver's BMS Simulation App - DirtStack Review", url: "https://dirtstack.com/productreviewpost/battery-performance-with-nebulasolver-bms-simulation-app" },
    { title: "Create Bode Plots on NebulaSolver.com - DirtStack Review", url: "https://dirtstack.com/productreviewpost/create-bode-plots-on-nebulasolver" },
    { title: "Circuit Simulations with NebulaSolver's App - DirtStack Review", url: "https://dirtstack.com/productreviewpost/circuit-simulations-with-nebulasolver" }
];
