/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {Typography, Container, CardContent, Card, CircularProgress} from '@mui/material';
import { styled } from '@mui/system';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {Helmet} from "react-helmet";

const StyledCard = styled(Card)({
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    padding: '2rem',
    marginTop: '0rem',
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem',
    // border: '2px solid #262931'
});

const StyledTypography = styled(Typography)({
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '600',
    marginTop: '1rem',
});

const StyledTypographyBio = styled(Typography)({
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    marginBottom: '1rem',
});

const StyledLink = styled('a')({
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const StyledImageContainer = styled('div')({
    paddingTop: '56.25%', // 16:9 ratio
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: '15px',
    marginBottom: '10px',
    backgroundColor:'#f2f2f2',
    // border: '2px solid #262931'
});

const StyledList = styled('ul')({
    listStyleType: 'none',
    paddingLeft: 0,
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '15px',
    backgroundColor: '#f2f2f2',
    border: '2px solid #f2f2f2'
});

const StyledListItem = styled('li')({
    borderBottom: '1px solid #e0e0e0',
    marginBottom: '10px',
    paddingBottom: '10px',
    paddingTop: '10px',
    // backgroundColor:'#f2f2f2'
    // border: '2px solid #262931'
});

const StyledTextContainer = styled('div')({
    paddingLeft: '15px',  // Added left padding to this container
});

const OriginalPrice = styled(Typography)({
    textDecoration: 'line-through',
    marginRight: '5px',
    color: 'gray',
    display: 'inline',
});

const DiscountedPrice = styled(Typography)({
    color: '#008080',
    display: 'inline',
});

const DealsComponent = () => {
    const [deals, setDeals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch("/.netlify/functions/getDealItems")
            .then(response => response.json())
            .then(data => {
                setDeals(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching latest deals:", error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>DirtStack - Deals</title>
                <meta property="og:title" content="DirtStack - Deals" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://dirtstack.com/deals" />
                <meta property="og:description" content="DirtStack - Deals - Explore Deals on DirtStack" />
                <link rel="alternate" hrefLang="en" href={`https://dirtstack.com/deals`} />
                <link rel="alternate" hrefLang="x-default" href={`https://dirtstack.com/deals`} />
            </Helmet>
            <box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 0
                }}

            >
                <StyledList>
                    {deals.map(deal => (
                        <StyledListItem
                            key={deal.deal.id}
                        >
                            <StyledLink
                                href={deal.deal.dealUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <StyledImageContainer
                                    style={{
                                        backgroundImage: `url(${deal.deal.fileUrl})`
                                }}
                                />
                                <StyledTextContainer>
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        sx={{
                                            color:'#4b5360'
                                        }}
                                    >
                                        {deal.deal.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '0.8rem',
                                            color: 'gray' }}
                                        align="left"
                                    >
                                        Start Date: {deal.deal.dealStartDate}
                                    </Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                    }}
                                    >
                                        <OriginalPrice
                                            variant="body2"
                                        >${deal.deal.originalPrice}
                                        </OriginalPrice>
                                        <DiscountedPrice
                                            variant="body2">${deal.deal.discountedPrice}
                                        </DiscountedPrice>
                                        {deal.deal.discountPercentage && (
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    marginLeft: '5px',
                                                    color: '#008080'
                                            }}
                                            >
                                                ({deal.deal.discountPercentage}% off)
                                            </Typography>
                                        )}
                                    </div>
                                </StyledTextContainer>
                            </StyledLink>
                        </StyledListItem>
                    ))}
                </StyledList>

            </box>

        </Container>
    );
};

export default DealsComponent;
