/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Typography, Box, Card, CardContent, Link } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Container } from '@mui/material';
import { Helmet } from "react-helmet";
import { siteUrls } from './SiteUrls';  // Import the siteUrls

const rainbowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const hoverAnimation = keyframes`
  0% { color: #333333; }
  100% { color: #9DC183; }
`;

const SiteIndex = () => {
    return (
        <Container maxWidth="md">
            <Helmet>
                <title>Site Index - DirtStack</title>
                <link rel="canonical" href={`https://dirtstack.com/siteindex`} />
                <meta
                    name="description"
                    content="Dirtstack - Find all the urls in DirtStack.com."
                />
                <link rel="alternate" hrefLang="en" href={`https://dirtstack.com/siteindex`} />
                <link rel="alternate" hrefLang="x-default" href={`https://dirtstack.com/siteindex`} />
                <meta property="og:title" content="DirtStack - Site Index" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://i.imgur.com/jrbqoWp.png" />
                <meta property="og:url" content="https://dirtstack.com/siteindex" />
            </Helmet>

            <Box sx={{ marginTop: '0px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '14px' }}>
                    <Card sx={{ boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)', backgroundColor: '#f2f2f2', borderRadius: '15px', border: '2px solid #35353500', width: '100%', mt: 2 }}>
                        <CardContent sx={{ padding: '14px 0px' }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', letterSpacing: '0.05em', background: 'linear-gradient(to right, #f06, #48f, #9DC183, #ff0)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', animation: `${rainbowAnimation} 10s ease infinite`, backgroundSize: '200% 200%', '@media (max-width:600px)': { fontSize: '1.2em' }, marginBottom: '10px' }}>
                                Site Index
                            </Typography>
                            <Box sx={{ textAlign: 'left', margin: '0 30px', maxWidth: 'false' }}>
                                {siteUrls.map((post, index) => (
                                    <Link
                                        key={index}
                                        href={post.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            display: 'block',
                                            mb: 1,
                                            color: '#333333',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                animation: `${hoverAnimation} 0.3s forwards`
                                            }
                                        }}
                                    >
                                        <i className="fas fa-angle-right" style={{ marginRight: '10px', color: '#9DC183' }}></i>
                                        {post.title}
                                    </Link>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Container>
    );
};

export default SiteIndex;
