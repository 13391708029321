/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, CardMedia, CardContent, Typography, Container, Link, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';

const StyledCardMedia = styled(CardMedia)({
    paddingTop: '56.25%', // 16:9
});

const StyledCardVideo = styled('video')({
    width: '100%',
    height: 'auto',
});

const StyledCard = styled(Card)({
    maxWidth: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    borderRadius: '15px',
    margin: '1em auto', // for centering and spacing
});

const formatPrice = (priceRange) => {
    if (priceRange.startsWith("Subscription|")) {
        const parts = priceRange.split('|');
        return `$${parts[1]} ${parts[2]} cloud bundle`;
    } else if (Number(priceRange) === 0) {
        return 'Free';
    }
    return `$${priceRange}`;
};

function ProductReviewPost() {
    const { slug } = useParams();
    const [item, setItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.post('/.netlify/functions/getProductReviewItem', { slug })
            .then(response => {
                setItem(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching item:', error);
                setIsLoading(false);
            });
    }, [slug]);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (!item) {
        return <p>No item found for this id.</p>;
    }

    return (
        <Container maxWidth="md">
            <Helmet>
                <title>{item.title ? `${item.title} - DirtStack` : 'Product Review - DirtStack'}</title>
                <link rel="canonical" href={`https://dirtstack.com/productreviewpost/${slug}`} />
                <meta
                    name="description"
                    content={item.shortDescription ? `${item.shortDescription.substring(0, 150)}...` : "Explore detailed reviews on DirtStack."}
                />
                <link rel="alternate" hrefLang="en" href={`https://dirtstack.com/productreviewpost/${slug}`} />
                <link rel="alternate" hrefLang="x-default" href={`https://dirtstack.com/productreviewpost/${slug}`} />
            </Helmet>
            <StyledCard>
                {item.fileUrl && item.fileUrl.endsWith('.mp4') ? (
                    <StyledCardVideo controls>
                        <source src={item.fileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </StyledCardVideo>
                ) : (
                    <StyledCardMedia
                        image={item.fileUrl ? item.fileUrl : "https://via.placeholder.com/640x360"}
                        title="Item image"
                    />
                )}
                <CardContent>
                    <Typography
                        variant="h5"
                        sx={{
                            color:'#333333'
                        }}
                    >
                        {item.title}
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: item.priceRange.startsWith("Subscription|") ? 'teal' : 'teal',
                        }}
                    >
                        {formatPrice(item.priceRange)}
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#0c79a4'
                        }}
                    >
                        {/* Use Link component here */}
                        <Link href={item.affiliateUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'darkgrey' }}>
                            {item.affiliateUrl}
                        </Link>
                    </Typography>
                </CardContent>
            </StyledCard>
            <div
                className="markdown-container"
                style={{
                    backgroundColor: '#fff',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '15px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'left',
                    color: '#4b5360',
                }}
                dangerouslySetInnerHTML={{ __html: item.detailedReview }}
            />
            <div className="markdown-container" style={{
                backgroundColor:'#fff',
                padding:'15px',
                borderRadius:'15px',
                marginBottom:'15px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                textAlign:'left',
                color:'#4b5360'
            }}>
                <CardContent>
                    <Typography
                        variant="h5"
                        sx={{
                            color:'#333333'
                        }}
                    >
                        Pros
                    </Typography>
                </CardContent>
                <ReactMarkdown>{item.pros}</ReactMarkdown>
            </div>
            <div className="markdown-container" style={{
                backgroundColor:'#fff',
                padding:'15px',
                borderRadius:'15px',
                marginBottom:'15px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                textAlign:'left',
                color:'#4b5360'
            }}>
                <CardContent>
                    <Typography
                        variant="h5"
                        sx={{
                            color:'#333333'
                        }}
                    >
                        Cons
                    </Typography>
                </CardContent>
                <ReactMarkdown>{item.cons}</ReactMarkdown>
            </div>
            <div className="markdown-container" style={{
                backgroundColor:'#fff',
                padding:'15px',
                borderRadius:'15px',
                marginBottom:'15px',
                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                textAlign:'left',
                color:'#4b5360'
            }}>
                <CardContent>
                    <Typography
                        variant="h5"
                        sx={{
                            color:'#333333'
                        }}
                    >
                        Rating
                    </Typography>
                </CardContent>
                <ReactMarkdown>{'&nbsp;&nbsp;&nbsp;&nbsp;' + item.recommendationLevel}</ReactMarkdown>
            </div>
        </Container>
    );
}

export default ProductReviewPost;
