/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { motion } from 'framer-motion';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    TextField, // import TextField
    InputAdornment,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import PostAddIcon from '@mui/icons-material/PostAdd';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth0 } from '@auth0/auth0-react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PeopleIcon from '@mui/icons-material/People';
import ShareIcon from '@mui/icons-material/Share';
import FeedbackIcon from '@mui/icons-material/Feedback';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EventIcon from '@mui/icons-material/Event';
import PortraitIcon from '@mui/icons-material/Portrait';
import ForumIcon from '@mui/icons-material/Forum';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentIcon from '@mui/icons-material/Payment';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import ExploreIcon from '@mui/icons-material/Explore';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import FeedIcon from '@mui/icons-material/Feed';
import ReviewsIcon from '@mui/icons-material/Reviews';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { FaBlog } from "react-icons/fa";
import { FaSitemap } from "react-icons/fa";

const drawerWidth = 240;

const drawerVariant = {
    hidden: { x: '-100%' },
    visible: { x: '0%', transition: { duration: 0.5 } },
    exit: { x: '-100%', transition: { ease: 'easeInOut' } },
};

const NavBar = ({setSearchString}) => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [localSearchString, setLocalSearchString] = useState('');

    useEffect(() => {
        setSearchString(localSearchString);
    }, [localSearchString]);

    const handleSearchChange = (event) => {
        setLocalSearchString(event.target.value);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawerItems = [
        {
            text: 'Home',
            icon: <HomeIcon style={{
                color: '#220070'
            }}/>,
            route: '/',
        },
        {
            text: 'Blog',
            icon: <RssFeedIcon style={{
                color: '#2a007a'
            }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
            route: '/blogposts',
        },
        {
            text: 'Site Index',
            icon: <FaSitemap style={{
                color: '#2a007a',
                fontSize: '20px'
            }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
            route: '/siteindex',
        },
        {
            text: 'Deals',
            icon: <LocalOfferIcon style={{
                color: '#2e007f'
            }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
            route: '/deals',
        },
        // {
        //     text: 'Deals',
        //     icon: <LocalOfferIcon style={{
        //         color: '#2e007f'
        //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
        //     route: '/latestdeals',
        // },
        // {
        //     text: 'Latest',
        //     icon: <RssFeedIcon style={{
        //         color: '#2a007a'
        //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
        //     route: '/latestblogposts',
        // },
        ...(isAuthenticated
            ? [
                // {
                //     text: 'Profile',
                //     icon: <AccountCircleIcon style={{
                //         color: '#330085'
                //     }}/>, // import AccountCircleIcon from '@mui/icons-material/AccountCircle'
                //     route: '/profile',
                // },
                // {
                //     text: 'My Pets',
                //     icon: <FormatListNumberedRtlIcon style={{
                //         color: '#44009A'
                //     }}/>,
                //     route: '/mypets',
                // },
                // {
                //     text: 'Owner',
                //     icon: <PersonIcon style={{
                //         color: '#450EFF'
                //     }}/>,
                //     route: '/createPetOwner',
                // },
                // {
                //     text: 'Pet',
                //     icon: <PetsIcon style={{
                //         color: '#327FBD'
                //     }}/>,
                //     route: '/createPet',
                // },
                // {
                //     text: 'Post',
                //     icon: <PostAddIcon style={{
                //         color: '#20EFCC'
                //     }}/>,
                //     route: '/post',
                // },
                {
                    text: 'Favorites',
                    icon: <FavoriteIcon style={{
                        color: '#22F3F1'
                    }}/>, // import FavoriteIcon from '@mui/icons-material/Favorite';
                    route: '/favorites',
                },
                {
                    text: 'Saved',
                    icon: <BookmarkIcon style={{
                        color: '#5A9FF6'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/saved',
                },
                // {
                //     text: 'Following',
                //     icon: <PeopleIcon style={{
                //         color: '#928BFB'
                //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                //     route: '/following',
                // },
                {
                    text: 'Shared With Me',
                    icon: <ShareIcon style={{
                        color: '#CA77FF'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/sharedwithme',
                },
                // {
                //     text: 'Community',
                //     icon: <ForumIcon style={{
                //         color: '#E200F7'
                //     }}/>,
                //     route: '/createCommunity',
                // },
                // {
                //     text: 'Community Post',
                //     icon: <AddBoxIcon style={{
                //         color: '#E759C2'
                //     }}/>,
                //     route: '/communityPost',
                // },
                // {
                //     text: 'Communities',
                //     icon: <GroupsIcon style={{
                //         color: '#EC928D'
                //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                //     route: '/communities',
                // },
                {
                    text: 'Shopping',
                    icon: <StorefrontIcon style={{
                        color: '#F1CB58'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/shop',
                },
                // {
                //     text: 'Events',
                //     icon: <EventIcon style={{
                //         color: '#F6F424'
                //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                //     route: '/events',
                // },
                // {
                //     text: 'People',
                //     icon: <PortraitIcon style={{
                //         color: '#FF901B'
                //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                //     route: '/publicfigures',
                // },
                {
                    text: 'Payments',
                    icon: <PaymentIcon style={{
                        color: '#FF6B16'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/payments',
                },
                // {
                //     text: 'Polls',
                //     icon: <HowToVoteIcon style={{
                //         color: '#FF4611'
                //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                //     route: '/polls',
                // },

                {
                    text: 'Feedback',
                    icon: <FeedbackIcon style={{
                        color: '#FF210C'
                    }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                    route: '/feedback',
                },

                // {
                //     text: 'Product Reviews',
                //     icon: <ReviewsIcon style={{
                //         color: '#FF210C'
                //     }}/>, // import BookmarkIcon from '@mui/icons-material/Bookmark';
                //     route: '/productreviews',
                // },
            ]
            : []),
    ];

    return (
        <>
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#ffee8e',
                    padding:'3px'
            }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="gray"
                            aria-label="menu"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon style={{
                                color: '#333333'
                            }} />
                        </IconButton>
                        <Typography variant="h6" component="div"
                                    sx={{
                                        flexGrow: 1,
                                        color: '#333333',
                                        marginRight: '1em'
                        }}
                        >
                            DirtStack - Beta
                        </Typography>
                        <TextField
                            sx={{ marginLeft: 'auto' }} // this makes it stick to the right side
                            variant="outlined"
                            size="small"
                            placeholder="Search"
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </Box>

                    <Avatar
                        alt="DirtStack Logo"
                        src="/apple-touch-icon.png"
                        sx={{
                            width: 56,
                            height: 56,
                            position: 'absolute',  // Add this
                            left: '50%',  // Add this
                            transform: 'translateX(-50%)',  // Add this
                        }}
                    />
                    <Box sx={{ width: '56px' }} /> {/* Add an empty box to balance the IconButton space */}
                </Toolbar>


            </AppBar>
            <Box component="nav"
                 sx={{
                     width: { md: drawerWidth },
                     flexShrink: { md: 0 }
            }}
            >
                <Drawer
                    variant="persistent"
                    open={drawerOpen}
                    sx={{
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            top: '64px', // AppBar height on desktop devices
                            height: 'calc(100% - 64px)',
                            backgroundColor:'#e6e6e6'
                        },
                    }}
                >
                    <List>
                        {drawerItems.map((item, index) => (
                            <ListItem button key={index} component={RouterLink} to={item.route}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                    sx={{
                                        color:'#333333'
                                    }}

                                    primary={item.text}
                                />
                            </ListItem>
                        ))}
                        {!isAuthenticated ? (
                            <ListItem button onClick={loginWithRedirect}>
                                <ListItemIcon>
                                    <LockOpenIcon style={{ color: '#FF321A'}}/>
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        color:'#333333'
                                    }}

                                    primary="Login" />
                            </ListItem>
                        ) : (
                            <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
                                <ListItemIcon>
                                    <ExitToAppIcon style={{ color: '#FF321A'}}/>
                                </ListItemIcon>
                                <ListItemText
                                    sx={{
                                        color:'#333333'
                                    }}

                                    primary="Logout" />
                            </ListItem>
                        )}
                    </List>
                </Drawer>
            </Box>
            <Box component="main"
                 sx={{ flexGrow: 1, p: 3, ml: { md: drawerWidth }, mt: { xs: '56px', sm: '64px' } }}
            >
                {/* your main content goes here */}
            </Box>
        </>
    );
};

export default NavBar;
